import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { InternalAuthenticationClient } from '@shared/data-access/common';
import { SharedUiLayoutFeedback } from '@shared/ui/layout';
import { ucFirst } from '@shared/util/code';
import { NgStringPipesModule } from 'ngx-pipes';
import { firstValueFrom } from 'rxjs';
import { DialogAuthenticatorInfoComponent } from '../../dialog-authenticator-info/dialog-authenticator-info.component';

@Component({
  selector: 'lsf-auth-normal-internal',
  templateUrl: './normal-internal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslocoModule,
    MatCardModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatButtonModule,
    RouterLink,
    SharedUiLayoutFeedback,
    NgStringPipesModule,
  ],
})
export class NormalInternalComponent implements OnInit {
  twoFactorCode: string = null!;
  username: string = null!;
  password: string = null!;
  tfa!: boolean;

  constructor(
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private internalAuthenticationClient: InternalAuthenticationClient,
    private activatedRoute: ActivatedRoute,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.tfa = this.activatedRoute.snapshot.data['tfa'] ?? true;
  }

  onOpenDialog(): void {
    this.matDialog.open(DialogAuthenticatorInfoComponent, { maxWidth: 950 });
  }

  async login(form: NgForm): Promise<void> {
    if (!form.valid) {
      return;
    }
    await firstValueFrom(this.internalAuthenticationClient.login(this.username, this.password, this.twoFactorCode));
    // idsrv.session is not a http cookie and is blocked in incognito mode
    await validateCookie(this.snackBar, ucFirst(this.translocoService.translate('login._sentences.Cookies_blocked')));
    const returnUrl = this.activatedRoute.snapshot.queryParams['ReturnUrl'];
    // returnUrl should navigate to prefetch token via authorize/callback https://localhost:5015/connect/authorize/callback?client_id=Desan&redirect_uri=http://localhost:4215/RoaPortal/auth/pre-token-fetch
    window.location.href = returnUrl === undefined ? '' : returnUrl;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function validateCookie(snackBar: MatSnackBar, msg: string) {
  // cookies kunnen niet getest worden op prod. Alleen lokaal werkt dit
  // if (!document.cookie.length || !document.cookie.includes('idsrv.session')) {
  //   snackBar.open(msg);
  //   await new Promise((r) => setTimeout(r, 3000));
  // }
}

import { KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule, NgForm, NgModel, ValidatorFn } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { FocusDirective } from '@shared/ui/directives';
import { SharedUiLayoutFeedback } from '@shared/ui/layout';
import { safeSessionStorage } from '@shared/util/code';
import { NgStringPipesModule } from 'ngx-pipes';
import { PasswordValidatorDirective } from '../core/password-validator.directive';
import { emailParameterConfirmationCode, emailParameterUserId } from '../login/session-storage';

@Component({
  selector: 'lsf-auth-set-password',
  templateUrl: './set-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslocoModule,
    FormsModule,
    SharedUiLayoutFeedback,
    MatFormFieldModule,
    MatInputModule,
    FocusDirective,
    PasswordValidatorDirective,
    NgFor,
    NgIf,
    MatButtonModule,
    KeyValuePipe,
    NgStringPipesModule,
  ],
})
export class SetPasswordComponent implements AfterViewInit {
  code: string;
  userId: string;
  twoFaCode: string = null!;
  isDirtyMatcher = new ShowOnDirtyErrorStateMatcher();
  @Input() buttonText: string = null!;
  @Input() showTwoFa = false;
  @Output() formOk = new EventEmitter();
  @ViewChild('password') private password: NgModel = null!;
  @ViewChild('confirmPassword') private confirmPassword: NgModel = null!;
  constructor(
    private route: ActivatedRoute,
    private translocoService: TranslocoService,
  ) {
    this.code = this.route.snapshot.queryParams['c'] ?? safeSessionStorage.getItem(emailParameterConfirmationCode)!;
    this.userId = this.route.snapshot.queryParams['userid'] ?? safeSessionStorage.getItem(emailParameterUserId)!;
  }

  ngAfterViewInit(): void {
    this.confirmPassword.control.setValidators(this.mismatchedPasswords());
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      this.formOk.emit({ password: this.password.value, twoFaCode: this.twoFaCode });
    }
  }

  private mismatchedPasswords = (): ValidatorFn => () =>
    this.password.value === this.confirmPassword.value
      ? null
      : { mismatchedPasswords: this.translocoService.translate('login._sentences.Passwords_do_not_match') };
}

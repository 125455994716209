import { CdkPortalOutlet, CdkPortalOutletAttachedRef, ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, ComponentRef, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { getEnvironment } from '@shared/util/infrastructure';
import { NgStringPipesModule } from 'ngx-pipes';
import { MicrosoftLoginComponent } from '../../microsoft-login/microsoft-login.component';

@Component({
  selector: 'lsf-auth-normal-external',
  templateUrl: './normal-external.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslocoModule, MicrosoftLoginComponent, NgStringPipesModule, CdkPortalOutlet],
})
export class NormalExternalComponent implements OnInit {
  @Input() prompt = false;
  otherProvider?: ComponentPortal<{ prompt: boolean }>;
  constructor(private activatedRoute: ActivatedRoute) {}

  onComponentRendering(evt: CdkPortalOutletAttachedRef) {
    const compRef = evt as ComponentRef<unknown>;
    const compInstance = compRef.instance as { prompt: boolean };
    compInstance.prompt = this.prompt;
  }

  ngOnInit() {
    // TODO remove later: this is to assure that the new provider is not going to production by accident
    if (this.activatedRoute.snapshot.data['otherProvider'] && getEnvironment(window.location) !== 'production') {
      this.otherProvider = new ComponentPortal(this.activatedRoute.snapshot.data['otherProvider']);
    }
  }
}

import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { Title } from '@angular/platform-browser';
import { TranslocoModule } from '@jsverse/transloco';
import { SharedUiLayoutFeedback } from '@shared/ui/layout';
import { safeSessionStorage } from '@shared/util/code';
import { NgStringPipesModule } from 'ngx-pipes';
import { NormalExternalComponent } from './normal-external/normal-external.component';
import { NormalInternalComponent } from './normal-internal/normal-internal.component';

@Component({
  selector: 'lsf-auth-normal-login',
  templateUrl: './normal-login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    SharedUiLayoutFeedback,
    TranslocoModule,
    MatCardModule,
    NormalInternalComponent,
    MatDividerModule,
    NormalExternalComponent,
    NgStringPipesModule,
  ],
})
export class NormalLoginComponent {
  error: string | null;
  constructor(title: Title) {
    title.setTitle('Login');
    this.error = safeSessionStorage.getItem('error');
    safeSessionStorage.removeItem('error');
  }
}

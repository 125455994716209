import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { RouterLink } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { NgStringPipesModule } from 'ngx-pipes';

@Component({
  selector: 'lsf-auth-logout',
  templateUrl: './logout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslocoModule, MatCardModule, MatButtonModule, RouterLink, NgStringPipesModule],
})
export class LogoutComponent {}

import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { EnvironmentProviders, inject, provideAppInitializer, Provider } from '@angular/core';
import { AppSettingsService } from '@shared/util/infrastructure';
import { authInterceptor, OidcSecurityService, provideAuth, StsConfigLoader } from 'angular-auth-oidc-client';
import { authFactory, configureAuth } from './core/auth-config';
import { credentialsInterceptor } from './core/credentials.interceptor';

export const authConfig: Provider | EnvironmentProviders = [
  provideAuth({
    loader: {
      provide: StsConfigLoader,
      useFactory: configureAuth,
      deps: [AppSettingsService],
    },
  }),
  provideHttpClient(withInterceptors([credentialsInterceptor, authInterceptor()])),
  provideAppInitializer(() => {
    const initializerFn = authFactory(inject(OidcSecurityService), inject(AppSettingsService));
    return initializerFn();
  }),
];

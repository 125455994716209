import { ChangeDetectorRef, Directive, Input } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { CurrentUserClient } from '@shared/data-access/common';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap, tap } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[passwordValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: PasswordValidatorDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class PasswordValidatorDirective implements AsyncValidator {
  @Input() userId: string = null!;
  @Input() code: string = null!;
  constructor(
    private userManagementClient: CurrentUserClient,
    private cdr: ChangeDetectorRef,
    private translocoService: TranslocoService,
  ) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return control.valueChanges.pipe(
      debounceTime(250), // will cancel not finished request what will cause 500 errors on server 'The client has disconnected'
      distinctUntilChanged(),
      switchMap((x) => this.userManagementClient.validatePassword(this.userId, x, this.code)),
      map((x) => (x.succeeded ? null : x.errors.map((x) => this.translocoService.translate(x)))),
      tap(() => setTimeout(() => this.cdr.detectChanges(), 0)), // we need a time out because the form become invalid async
      first(),
    );
  }
}

import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { Title } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { SharedUiLayoutFeedback } from '@shared/ui/layout';
import { safeSessionStorage, ucFirst } from '@shared/util/code';
import { NgStringPipesModule } from 'ngx-pipes';
import { emailParameterConfirmationCode, emailParameterUserId } from '../session-storage';
import { ConfirmExternalComponent } from './confirm-external/confirm-external.component';
import { ConfirmInternalQrStepComponent } from './confirm-internal-qr-step/confirm-internal-qr-step.component';
import { ConfirmInternalComponent } from './confirm-internal/confirm-internal.component';

@Component({
  selector: 'lsf-auth-confirm-login',
  templateUrl: './confirm-login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    SharedUiLayoutFeedback,
    TranslocoModule,
    MatCardModule,
    RouterLink,
    ConfirmInternalComponent,
    MatDividerModule,
    ConfirmExternalComponent,
    ConfirmInternalQrStepComponent,
    NgStringPipesModule,
  ],
})
export class ConfirmLoginComponent {
  error: string | null;
  @Output() toNormalLogin = new EventEmitter();
  data: { userId: string; qrLink: string } | null = null;
  constructor(title: Title, translocoService: TranslocoService) {
    title.setTitle(ucFirst(translocoService.translate('login.confirm_account')));
    this.error = safeSessionStorage.getItem('error');
    safeSessionStorage.removeItem('error');
  }

  registrationDone($event: { userId: string; qrLink: string } | null) {
    this.data = $event;
  }

  // Do not remove this one, it is added via json.
  navigateToLogin() {
    safeSessionStorage.removeItem(emailParameterUserId);
    safeSessionStorage.removeItem(emailParameterConfirmationCode);
    this.toNormalLogin.emit();
  }
}

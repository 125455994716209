import { DOCUMENT, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { ConfirmationModel, CurrentUserClient } from '@shared/data-access/common';
import { MxRecordValidatorDirective } from '@shared/ui/directives';
import { ucFirst } from '@shared/util/code';
import { NgStringPipesModule } from 'ngx-pipes';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lsf-auth-reset-password-request',
  templateUrl: './reset-password-request.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslocoModule,
    MatCardModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MxRecordValidatorDirective,
    NgIf,
    MatButtonModule,
    NgStringPipesModule,
  ],
})
export class ResetPasswordRequestComponent implements OnInit {
  confirmation: ConfirmationModel = {} as ConfirmationModel;

  constructor(
    private currentUserClient: CurrentUserClient,
    private snackBar: MatSnackBar,
    private title: Title,
    private translocoService: TranslocoService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    this.title.setTitle(this.translocoService.translate('login._sentences.Recover_password'));
  }

  async reset(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const model: ConfirmationModel = { email: this.confirmation.email, resetUrl: `${this.document.baseURI}auth/reset-password/?userid={0}&c={1}` };
    await firstValueFrom(this.currentUserClient.resetPassword(model));
    this.snackBar.open(
      this.translocoService.translate('login._sentences.E-mail_with_password_send'),
      ucFirst(this.translocoService.translate('common._actions.close')),
      { duration: 10000 },
    );
  }
}

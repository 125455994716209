import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { InternalAuthenticationClient } from '@shared/data-access/common';
import { FocusDirective } from '@shared/ui/directives';
import { safeSessionStorage, ucFirst } from '@shared/util/code';
import { UserError } from '@shared/util/infrastructure';
import { QRCodeComponent } from 'angularx-qrcode';
import { NgStringPipesModule } from 'ngx-pipes';
import { firstValueFrom } from 'rxjs';
import { emailParameterConfirmationCode, emailParameterUserId } from '../../session-storage';

@Component({
  selector: 'lsf-auth-confirm-internal-qr-step',
  templateUrl: './confirm-internal-qr-step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslocoModule,
    FormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    FocusDirective,
    NgIf,
    MatDividerModule,
    MatButtonModule,
    NgStringPipesModule,
    QRCodeComponent,
  ],
})
export class ConfirmInternalQrStepComponent {
  @Input() data: { userId: string; qrLink: string } = null!;
  qrCode: string = null!;

  constructor(
    private internalAuthenticationClient: InternalAuthenticationClient,
    private snackBar: MatSnackBar,
    private translocoService: TranslocoService,
  ) {}

  async confirmQr() {
    if (this.qrCode) {
      await firstValueFrom(this.internalAuthenticationClient.registerTwoFa(this.data.userId, this.qrCode));
      this.onSuccess();
    } else {
      throw new UserError(
        this.translocoService.translate('login._sentences.The_code_is_invalid'),
        ucFirst(this.translocoService.translate('login.invalid_code')),
      );
    }
  }

  private async onSuccess() {
    const url = safeSessionStorage.getItem('idpAuthorizeCallback')!;
    const snackbar = this.snackBar.open(this.translocoService.translate('login._sentences.Account_activated'));
    safeSessionStorage.removeItem(emailParameterConfirmationCode);
    safeSessionStorage.removeItem(emailParameterUserId);
    await firstValueFrom(snackbar.afterDismissed());
    window.location.href = url;
  }
}
